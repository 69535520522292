import React from 'react';

export default function Androidapp() {
  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-2xl mb-4">Accede a la beta privada de FotoExamen</h2>
          <p className="text-lg mb-6">Sigue estos dos sencillos pasos para probar nuestra APP antes que nadie:</p>
          <ol className="list-decimal list-inside space-y-6">
            <li className="bg-gray-100 p-4 rounded-lg">
              <p className="font-semibold mb-2">Únete al grupo de Google:</p>
              <p className="mb-3">Esto te permitirá descargar la APP.</p>
              <a href="https://groups.google.com/g/fotoexamen" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                Unirse al grupo de Google
              </a>
            </li>
            <li className="bg-gray-100 p-4 rounded-lg">
              <p className="font-semibold mb-2">Accede a la beta en Play Store:</p>
              <p className="mb-3">Una vez en el grupo, podrás descargar la APP desde aquí.</p>
              <a href="https://play.google.com/store/apps/details?id=com.fotoexamen.android" target="_blank" rel="noopener noreferrer" className="btn btn-secondary">
                Unirse a la beta en Play Store
              </a>
            </li>
          </ol>
          <div className="card-actions justify-end mt-6">
            <p className="text-sm opacity-70">
              ¡Sé uno de los primeros en probar nuestra innovadora app!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}