import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

export default function Bienvenido({setShowNoTokenModal}) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nombre: "",
    edad: "",
    estudios: "",
    ciudad: "",
    dedicacion: "",
    otroDedicacion: "",
    encontro: "",
    otroEncontro: "",
    atencion: "",
    otroAtencion: "",
    simpatica: 3,
    directa: 3,
    empatica: 3,
    critica: 3,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const debounceTimeout = useRef(null);
  const [token, clearToken] = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        return;
      }

      fetch(apiUrl + "/api/easyexams/getprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
          setIsFirstLoad(false);
        }).catch((error) => {
          alert("Error recuperando sus datos. Por favor, recarge la página.");
        });
    };
    fetchUserData();
  }, [token, setShowNoTokenModal]);

  useEffect(() => {
    if (isFirstLoad || !token) {
      return;
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const forms = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        forms.append(key, value);
      }
      fetch(apiUrl + "/api/easyexams/saveprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: forms,
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => console.log(data));
    }, 1000);
  }, [formData, token, isFirstLoad]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSliderChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const nextStep = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }
    // Validations for each step
    if (step === 1) {
      if (!formData.nombre || !formData.edad || !formData.estudios || !formData.ciudad) {
        alert("Por favor, complete todos los campos obligatorios.");
        return;
      }
    } else if (step === 2) {
      if (!formData.dedicacion || !formData.encontro || !formData.atencion) {
        alert("Por favor, complete todos los campos obligatorios.");
        return;
      }
      if ((formData.dedicacion === "Otros" || formData.dedicacion === "Otros Estudios") && !formData.otroDedicacion) {
        alert("Por favor, especifique su ocupación.");
        return;
      }
      if (formData.encontro === "Otros" && !formData.otroEncontro) {
        alert("Por favor, especifique cómo nos encontró.");
        return;
      }
      if (formData.atencion === "Otros" && !formData.otroAtencion) {
        alert("Por favor, especifique qué le llama más de la aplicación.");
        return;
      }
    }
    
    setStep((prevStep) => prevStep + 1);
    if (step + 1 === 5) {
      window.location.href = "/";
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="container mx-auto p-4 my-8 md:p-16">
      <div className="flex justify-center mb-6">
        <div className="steps">
          <div
            data-content="📊"
            className={`step ${step >= 1 ? "step-neutral" : ""}`}
          >
            Datos
          </div>
          <div
            data-content="🔍"
            className={`step ${step >= 2 ? "step-neutral" : ""}`}
          >
            Más info
          </div>
          <div
            data-content="🤖"
            className={`step ${step >= 3 ? "step-neutral" : ""}`}
          >
            IA
          </div>
          <div
            data-content="🚀"
            className={`step ${step >= 4 ? "step-neutral" : ""}`}
          >
            ¡EMPIEZA YA!
          </div>
        </div>
      </div>

      {step === 1 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-1"
        >
          <h2 className="text-2xl font-bold mb-4">
            {" "}
            ¡Bienvenido a FotoExamen!{" "}
          </h2>
          <p className="mb-4">
            Para mejorar tu experiencia con nuestra IA avanzada y que los
            resultados de tus exámenes sean mejores y más precisos. Por favor,
            proporcione la siguiente información necesaria:
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">Nombre*</label>
              <input
                type="text"
                name="nombre"
                className="input input-bordered w-full"
                value={formData.nombre}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">Edad*</label>
              <input
                type="number"
                name="edad"
                className="input input-bordered w-full"
                value={formData.edad}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">Estudios*</label>
              <input
                type="text"
                name="estudios"
                className="input input-bordered w-full"
                value={formData.estudios}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">Ciudad*</label>
              <input
                type="text"
                name="ciudad"
                className="input input-bordered w-full"
                value={formData.ciudad}
                onChange={handleChange}
              />
            </div>
          </form>
          <button
            className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white mt-4"
            onClick={nextStep}
          >
            Siguiente
          </button>
        </motion.div>
      )}

      {step === 2 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-2"
        >
          <h2 className="text-2xl font-bold mb-4">Más información</h2>
          <p className="mb-4">Esto nos ayuda a mejorar a nosotros:</p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">
                ¿A qué se dedica?*
              </label>
              <select
                name="dedicacion"
                className="select select-bordered w-full"
                value={formData.dedicacion}
                onChange={handleChange}
              >
                <option value="">Seleccione una opción</option>
                <option value="Familiar">
                  Padre, madre o familiar de un estudiante
                </option>
                <option value="Profesor">Profesor</option>
                <optgroup label="Estudiante">
                  <option value="Master">Estudiante de Master</option>
                  <option value="Universidad">Estudiante de Universidad</option>
                  <option value="Formación Profesional">
                    Estudiante de Formación Profesional
                  </option>
                  <option value="Instituto">Estudiante de Instituto</option>
                  <option value="Colegio">Estudiante de Colegio</option>
                  <option value="Otros Estudios">Otros Estudios</option>
                </optgroup>
                <option value="Otros">Otros</option>
              </select>
            </div>
            {(formData.dedicacion === "Otros" ||
              formData.dedicacion === "Otros Estudios") && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Por favor, especifique*
                </label>
                <input
                  type="text"
                  name="otroDedicacion"
                  className="input input-bordered w-full"
                  value={formData.otroDedicacion}
                  onChange={handleChange}
                  placeholder="Especifique su ocupación"
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                ¿Cómo nos encontró?*
              </label>
              <select
                name="encontro"
                className="select select-bordered w-full"
                value={formData.encontro}
                onChange={handleChange}
              >
                <option value="">Seleccione una opción</option>
                <option value="Recomendación de un amigo">
                  Recomendación de un amigo
                </option>
                <option value="Busqueda en internet">
                  Búsqueda en internet (Google, Bing, etc.)
                </option>
                <optgroup label="Redes Sociales">
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Youtube">Youtube</option>
                </optgroup>
                <option value="Otros">Otros medios</option>
              </select>
            </div>

            {formData.encontro === "Otros" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Por favor, especifique*
                </label>
                <input
                  type="text"
                  name="otroEncontro"
                  className="input input-bordered w-full"
                  value={formData.otroEncontro}
                  onChange={handleChange}
                  placeholder="Especifique como nos encontró"
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                ¿Qué herramienta le llama más la atención?*
              </label>
              <select
                name="atencion"
                className="select select-bordered w-full"
                value={formData.atencion}
                onChange={handleChange}
              >
                <option value="">Seleccione una opción</option>
                <option value="Examen">Resolver exámenes</option>
                <option value="Esquema">Crear esquemas</option>
                <option value="Tutor">Chatear con tutores</option>
                <option value="Quiz">Crear un quiz</option>
                <option value="Resumen">Resumir texto</option>
                <option value="Subrayar">Subrayar texto</option>
                <option value="Corregir">Corregir exámenes</option>
                <option value="Apuntes">Tomar apuntes</option>
                <option value="Otros">Otras características</option>
              </select>
            </div>
            {formData.atencion === "Otros" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  Por favor, especifique*
                </label>
                <input
                  type="text"
                  name="otroAtencion"
                  className="input input-bordered w-full"
                  value={formData.otroAtencion}
                  onChange={handleChange}
                  placeholder="Especifique que le llama más de la aplicación"
                />
              </div>
            )}
          </form>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Anterior
            </button>
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={nextStep}
            >
              Siguiente
            </button>
          </div>
        </motion.div>
      )}

      {step === 3 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-3"
        >
          <h2 className="text-2xl font-bold mb-4">Personaliza tu IA</h2>
          <p className="mb-4">
            Actualmente en fase de desarrollo, puede que no note mucha
            personalidad y diferencia en las repuestas al ajustar estos
            parámetros, pero puedes indicarnos como quieres que sea tu IA ideal:
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">Simpática</label>
              <input
                type="range"
                name="simpatica"
                min="1"
                max="5"
                value={formData.simpatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Nada simpatica)</span>
                <span>2</span>
                <span>3 (Normalmente simpatica)</span>
                <span>4</span>
                <span>5 (Muy simpatica)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Directa</label>
              <input
                type="range"
                name="directa"
                min="1"
                max="5"
                value={formData.directa}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Nada directa)</span>
                <span>2</span>
                <span>3 (Normalmente directa)</span>
                <span>4</span>
                <span>5 (Muy directa)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Empática</label>
              <input
                type="range"
                name="empatica"
                min="1"
                max="5"
                value={formData.empatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Nada empatica)</span>
                <span>2</span>
                <span>3 (Normalmente empatica)</span>
                <span>4</span>
                <span>5 (Muy empatica)</span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">Crítica</label>
              <input
                type="range"
                name="critica"
                min="1"
                max="5"
                value={formData.critica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>1 (Nada critica)</span>
                <span>2</span>
                <span>3 (Normalmente critica)</span>
                <span>4</span>
                <span>5 (Muy critica)</span>
              </div>
            </div>
          </form>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Anterior
            </button>
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={nextStep}
            >
              Siguiente
            </button>
          </div>
        </motion.div>
      )}

      {step === 4 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-5"
        >
          <h2 className="text-2xl font-bold mb-4">
            ¿Qué ventajas ofrece FotoExamen?
          </h2>
          <p className="mb-4">
            Descubre las bueno de usar nuestra IA para mejorar tus
            calificaciones:
          </p>
          <div className="bg-base-200 p-6 rounded-lg mb-4 shadow-md">
            <ul className="list-disc list-inside space-y-2">
              <li className="p-2 rounded-lg">
                <strong>Resolver exámenes:</strong> Usa tareas, exámenes o
                ejercicios de años anteriores para preparar tus próximos
                exámenes.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Crear esquemas:</strong> Usa tu temario para crear tus
                propios esquemas y así estudiar de manera eficaz.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Charla con tutores de IA:</strong> Tutores disponibles
                de matemáticas, biología, física... para cosultar tus dudas.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Crear un tutor personalizado de tus apuntes:</strong> Si
                tienes dudas de tus apuntes, súbele tu temario y hazle cualquier
                pregunta como si fuera tu profesor.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Generar quizzes interactivos:</strong> Crea quizzes con
                varios tipos de preguntas para probar tus habilidades o para
                aprender de forma divertida.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Tomar apuntes:</strong> Sube un audio de tu clase y
                obtén apuntes de forma automática.
              </li>
              <li className="p-2 rounded-lg">
                <strong>Resumir, subrayar y corregir:</strong> Entre muchas
                otras cosas más.
              </li>
            </ul>
          </div>
          <p className="mb-4 font-bold text-lg">
            NO PIERDAS EL TIEMPO Y EMPIEZA YA A MEJORAR TUS CALIFICACIONES 🔟.
          </p>
          <div className="flex justify-between mt-4">
            <button
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              onClick={prevStep}
            >
              Anterior
            </button>
            <Link
              className="btn bg-gradient-to-r from-blue-500 to-purple-600 text-white"
              to="/"
            >
              Finalizar
            </Link>
          </div>
        </motion.div>
      )}

      <p className="mt-4 text-sm">
        Puedes modificar todo los datos en cualquier momento desde "Tu perfil".
      </p>
    </div>
  );
}
