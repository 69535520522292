import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import biologicPaladinImage from "../assets/biologic-paladin.webp";
import generalTutor from "../assets/general-tutor.webp";
import historyHunter from "../assets/history-hunter.webp";
import mathMage from "../assets/math-mage.webp";
import CurrentPath from "./CurrentPath";
import useAuth from "./useAuthToken";
import useCheckSubscription from "./useCheckSubscription";

const controller = new AbortController();
const { signal } = controller;

const apiUrl = process.env.REACT_APP_API_URL;

const predefinedTutors = [
  {
    id: 1,
    name: "Master Tutor",
    subject: "Profesor general",
    image: generalTutor,
  },
  {
    id: 2,
    name: "Math Mage",
    subject: "Profesor de Matemáticas",
    image: mathMage,
  },
  {
    id: 3,
    name: "Biologic Paladin",
    subject: "Profesor de Biología",
    image: biologicPaladinImage,
  },
  {
    id: 4,
    name: "History Hunter",
    subject: "Profesor de Historia",
    image: historyHunter,
  },
  {
    id: "custom", // Asegúrate de que este valor sea el mismo que el que buscas
    name: "Custom",
    subject: "Sube un pdf con el temario que quieras",
    image: generalTutor,
    isCustom: true, // Puedes añadir esta propiedad para diferenciar a los tutores personalizados
  },
];

const TutorCard = ({
  id,
  name,
  subject,
  image,
  isCustom,
  onSelect,
  isSelected,
}) => {
  const cardClasses = `transition-transform duration-300 ease-in-out ${
    isSelected ? "scale-105" : "scale-100"
  } ${
    isCustom ? "border-4 border-yellow-400 shadow-lg" : "border border-gray-200"
  } ${!isSelected && "opacity-50"}`;

  return (
    <div
      className={`p-4 m-2 cursor-pointer rounded-lg ${cardClasses}`}
      onClick={() => onSelect(id)}
    >
      <img src={image} alt={name} className="w-32 h-32 rounded-full mx-auto" />
      <h3 className="text-xl font-bold text-center mt-4">{name}</h3>
      <p className="text-center text-gray-600">{subject}</p>
    </div>
  );
};

// Nuevo componente TypingMessage
const TypingMessage = ({ text }) => {
  return <div className="p-2 my-1 rounded bg-green-200">Tutor: {text}</div>;
};

const Chat = React.forwardRef(({ assistantData, selectedTutor }, ref) => {
  const token = localStorage.getItem("token");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  //const [assistantData, setAssistantData] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(""); // Nuevo estado para el mensaje en construcción
  const [isFinal, setIsFinal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file); // Asegúrate de que el backend espera 'image' como clave

    // Aquí llamarías a la API como mostraste, usando formData
    try {
      const response = await fetch(apiUrl + "/api/easyexams/ocr", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Usa el token en la petición
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.nowpay) {
          // Controla la visibilidad del modal Stripe o redirecciona
          window.location.href = "https://www.fotoexamen.com/precios.html";
        } else {
          //setOcrText(responseData.texts[0]); // Actualiza el estado con el texto reconocido
          setNewMessage(responseData.texts[0]); // También puedes actualizar el mensaje nuevo para enviarlo directamente
        }
      } else {
        alert(
          "No se pudo detectar el texto en la imagen.  Asegúrese de que su imagen tenga un tamaño inferior a 20 MB y tenga uno de los siguientes formatos: .png, .jpeg, .gif o .webp."
        );
      }
    } catch (error) {
      console.error("Error processing the image:", error);
    }
  };

  //useEffect(() => {
  // Esta función solo se debe llamar una vez cuando el componente se monta
  // Por lo tanto, no depende de ninguna variable de estado o prop y se ejecuta solo al inicio
  const fetchData = async () => {
    if (!assistantData || !token || isFetching) return;
    setIsFetching(true);
    let promptutor;
    if (assistantData.tutor_name == "Math Mage") {
      promptutor =
        "Eres un tutor de matemáticas, pregúntale al alumno qué necesita";
    } else if (assistantData.tutor_name == "History Hunter") {
      promptutor =
        "Eres un tutor de Historia, pregúntale al alumno qué necesita";
    } else if (assistantData.tutor_name == "Biologic Paladin") {
      promptutor =
        "Eres un tutor de Biología, pregúntale al alumno qué necesita";
    } else {
      promptutor = "Eres un tutor general, pregúntale al alumno qué necesita";
    }

    await fetchEventSource(
      `${process.env.REACT_APP_API_URL}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: promptutor,
        }),
        onopen(res) {
          if (res.ok) console.log("Connection made", res);
          else console.error("Connection failed", res);
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);
          setCurrentMessage((prev) => prev + text_value);
          if (final) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          }
        },
        onclose() {},
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );

    setIsFetching(false);
  };
  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  useEffect(() => {
    if (isFinal) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: currentMessage, sender: "tutor" },
      ]);
      setCurrentMessage(""); // Limpia el mensaje en construcción para el próximo mensaje
      setIsFinal(false); // Restablece isFinal para el próximo mensaje
    }
  }, [isFinal, currentMessage]);

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    // Limpiamos el campo de entrada
    setIsLoading(true);
    setNewMessage("");
    // Agregamos el mensaje del usuario al estado de mensajes
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: messageText, sender: "user" },
    ]);

    await fetchEventSource(
      `${process.env.REACT_APP_API_URL}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST", // Asegúrate de que el método sea el correcto para tu API
        headers: {
          Authorization: `Bearer ${token}`, // Asegúrate de enviar el token si es necesario
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // Primer prompt
          prompt: messageText,
        }),
        onopen(res) {
          if (res.ok) {
          } else {
            console.error("Connection failed", res);
          }
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);

          // Comprobamos si el mensaje es final
          if (final) {
            // Si es final, agregamos el mensaje completo y restablecemos el mensaje parcial
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          } else {
            // Si no es final, simplemente actualizamos el mensaje parcial
            setCurrentMessage((prev) => prev + text_value);
          }
        },
        onclose() {
          controller.abort();
        },
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );
    setIsLoading(false);
  };
  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  // Componente para mostrar el mensaje que se está escribiendo
  const TypingMessage = ({ text }) => (
    <div className="p-2 my-1 rounded">
      <div className="chat-bubble">
        <ReactMarkdown
          rehypePlugins={[rehypeSanitize]}
          children={text}
          disallowedElements={["pre"]}
          unwrapDisallowed
        />
        <div className="text-xs">Escribiendo...</div>
      </div>
    </div>
  );

  const handleOpenFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full mt-4 p-4 bg-white shadow rounded-lg">
      <div
        className="mb-4 p-2 bg-gray-800 rounded h-[32rem] overflow-auto"
        style={{ minHeight: "16rem" }}
      >
        {messages.length === 0 ? (
          <div className="text-gray-400 text-center">Llamando al tutor...</div>
        ) : (
          <div className="overflow-auto h-full" ref={messagesEndRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat ${
                  msg.sender === "user" ? "chat-end" : "chat-start"
                }`}
              >
                <div className="chat-image avatar">
                  <div className="w-10 rounded-full">
                    {msg.sender !== "user" ? (
                      <img
                        alt="Avatar"
                        src={
                          selectedTutor &&
                          predefinedTutors.find((t) => t.id === selectedTutor)
                            .image
                        }
                      />
                    ) : (
                      <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content rounded-full w-12 pr-2">
                          <span>🙋🏻‍♂️</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="chat-header text-white">
                  {selectedTutor && msg.sender !== "user"
                    ? predefinedTutors.find((t) => t.id === selectedTutor).name
                    : "Tú"}{" "}
                  <time className="text-xs opacity-50">
                    {new Date().toLocaleTimeString().slice(0, 5)}
                  </time>
                </div>
                <div className="chat-bubble">
                  <ReactMarkdown
                    rehypePlugins={[rehypeSanitize]}
                    children={msg.text}
                    disallowedElements={["pre"]}
                    unwrapDisallowed
                  />
                </div>
                <div className="chat-footer opacity-50 text-white">
                  {msg.sender === "user" ? "Enviado" : "Visto"}
                </div>
              </div>
            ))}
            {currentMessage && <TypingMessage text={currentMessage} />}
          </div>
        )}
      </div>
      <div className="flex">
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <button
          type="button"
          className="p-2"
          onClick={handleOpenFileDialog}
          hidden={isLoading || messages.length === 0}
        >
          <FontAwesomeIcon icon={faCamera} className="w-6 h-6" />
        </button>
        <textarea // Cambia esto a 'textarea' para permitir múltiples líneas
          ref={inputRef} // Asignar la referencia aquí
          className="border rounded p-2 flex-grow h-16"
          placeholder="Escribe tu mensaje aquí..."
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          onKeyPress={(e) =>
            e.key === "Enter" && !e.shiftKey && handleSendMessage()
          } // Añade '!e.shiftKey' para permitir saltos de línea con Shift+Enter
          style={{ overflow: "hidden" }} // Evitar scrollbar
          hidden={isLoading || messages.length === 0}
        />
        {isLoading && (
          <div className="flex items-center space-x-2">
            <div className="p-2 my-1 rounded bg-green-100">
              Cargando la respuesta...{" "}
              {/* Replace this with your own loading animation */}
            </div>
            <div className="loading"></div>
          </div>
        )}
        <button
          className="bg-[#66cc8a] hover:bg-[#58b177] font-bold py-2 px-4 rounded ml-2"
          onClick={handleSendMessage}
          hidden={isLoading || messages.length === 0}
        >
          Enviar
        </button>
      </div>
    </div>
  );
});

const TutorsGallery = ({setShowNoTokenModal}) => {
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [assistantData, setAssistantData] = useState(null);
  const chatRef = useRef();
  const fileInputRef = useRef(null); // Referencia al input de archivos
  const [file, setFile] = useState(null); // Estado para guardar el archivo
  const [errorMessage, setErrorMessage] = useState(null);
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const [loadingIniciarChat, setLoadingIniciarChat] = useState(false);

  const handleSelectTutor = (id) => {
    setSelectedTutor(id);
    setShowForm(true);
    setShowChat(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // El primer archivo del input
    if (!file) {
      console.error("No se ha seleccionado ningún archivo");
      return;
    }
    setFile(file); // Guarda el archivo en el estado
  };

  const createAssistant = async (tutorName, file) => {
    if (!token) {
      console.error("No se encontró el token de autorización");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let body = null;

    if (tutorName === "Custom") {
      // Si el tutor es personalizado, se enviará el archivo
      const formData = new FormData();
      formData.append("file", file);
      formData.append("tutor_name", tutorName);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]); // Verifica si el archivo y otros datos se agregan correctamente
      }
      body = formData;
    } else {
      // Para otros tutores, no se envía el archivo
      const formData = new FormData();
      formData.append("tutor_name", tutorName);
      body = formData;
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/tutors`, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        console.error("Error al crear el asistente:", response.statusText);
        return;
      }

      const data = await response.json();
      setAssistantData({
        thread_id: data.thread_id,
        tutor_name: tutorName,
        assistant_id: data.assistant_id,
      });
    } catch (error) {
      console.error("Error al crear el asistente:", error);
    }
  };

  const handleStartConversation = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }
    
    if (subscriptionType === "No Subscription") {
      window.location.href="/ver-precios"
      return;
    }

    if (!selectedTutor) {
      return;
    }
    // Encuentra el tutor seleccionado en tu lista de tutores predefinidos
    const tutor = predefinedTutors.find((t) => t.id === selectedTutor);

    if (!tutor) {
      setErrorMessage("Es obligatorio seleccionar un tutor.");
      return;
    }

    if (tutor.name == "Custom") {
      if (!file) {
        setErrorMessage(
          "Es obligatorio subir un PDF para iniciar la conversación."
        );
        return;
      }
    }

    setLoadingIniciarChat(true);
    // Llama a createAssistant con el nombre del tutor seleccionado
    createAssistant(tutor.name, file)
      .then(() => {
        //chatRef.current.fetchData();
        setShowChat(true); // Asegúrate de mostrar el componente Chat después de crear el asistente
      })
      .finally(() => {
        setLoadingIniciarChat(false);
      });
  };

  useEffect(() => {
    if (showChat && chatRef.current) {
      chatRef.current.fetchData();
    }
  }, [showChat, chatRef.current]);

  const handleReturnToSelection = () => {
    setShowChat(false);
    setShowForm(false);
    setSelectedTutor(null);
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Asistentes Virtuales Personalizados para Estudiantes</h2>
        <p className="mb-4 text-gray-600">
          Descubre una nueva forma de aprender con nuestros tutores virtuales impulsados por inteligencia artificial. Sube tu PDF con el temario y obtén respuestas instantáneas a todas tus preguntas. Nuestra plataforma de e-learning revoluciona el estudio personalizado.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Tutorías online disponibles 24/7</li>
          <li className="mb-2">Aprendizaje adaptativo basado en IA</li>
          <li className="mb-2">Soporte académico personalizado para cada estudiante</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Interactúa con Tutores IA Especializados</h2>
        <p className="mb-4 text-gray-600">
          Selecciona entre una variedad de tutores IA, cada uno especializado en diferentes áreas académicas. Nuestro sistema de educación en línea te permite elegir el asistente virtual que mejor se adapte a tus necesidades de aprendizaje.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Amplia gama de tutores IA para diferentes materias</li>
          <li className="mb-2">Asistencia académica personalizada en tiempo real</li>
          <li className="mb-2">Plataforma de aprendizaje interactivo y dinámico</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Aprendizaje Basado en Tu Propio Material</h2>
        <p className="mb-4 text-gray-600">
          Aprovecha al máximo tus recursos de estudio. Sube tu PDF con el temario y deja que nuestro tutor IA lo analice. Esta innovadora herramienta de e-learning te permite hacer preguntas específicas sobre tu material de estudio.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Carga de PDFs con temarios personalizados</li>
          <li className="mb-2">Análisis inteligente de contenido académico</li>
          <li className="mb-2">Preguntas y respuestas sobre tu material específico</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Beneficios del Aprendizaje con IA</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Mejora en la comprensión de conceptos complejos</li>
          <li className="mb-2">Aumento de la retención de información</li>
          <li className="mb-2">Flexibilidad en horarios de estudio</li>
          <li className="mb-2">Adaptación a diferentes estilos de aprendizaje</li>
          <li className="mb-2">Preparación eficiente para exámenes y evaluaciones</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Características de Nuestros Tutores Virtuales</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Respuestas instantáneas a tus dudas académicas</li>
          <li className="mb-2">Explicaciones paso a paso de problemas complejos</li>
          <li className="mb-2">Sugerencias de recursos adicionales para profundizar</li>
          <li className="mb-2">Seguimiento del progreso de aprendizaje</li>
          <li className="mb-2">Adaptación continua a tu nivel de conocimiento</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Transforma Tu Experiencia de Aprendizaje</h2>
        <p className="text-gray-600">
          Nuestros tutores IA representan el futuro de la educación en línea. Ya sea que estés preparándote para un examen, trabajando en un proyecto complejo o simplemente buscando expandir tus conocimientos, nuestro sistema de tutoría virtual está diseñado para ayudarte a alcanzar tus metas académicas. Experimenta el poder del aprendizaje personalizado y adaptativo con nuestros asistentes virtuales basados en IA. ¡Comienza tu viaje hacia el éxito académico hoy mismo!
        </p>
      </section>
    </div>
  );

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="Tutores IA" />
      {!showChat && (
        <>
          <div className="mb-8 text-center max-w-xl mx-auto py-12">
            <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
              🤖 Chatea con Tutores IA
            </h1>
            <p>
              Selecciona un tutor IA para resolver todas tus dudas. Elige uno de
              los tutores predefinidos que se adapte a tus necesidades (¡Pronto
              se añadirán más!).
            </p>
          </div>
          <div className="flex flex-wrap mb-64 items-center justify-center">
            {predefinedTutors.map((tutor) => (
              <TutorCard
                key={tutor.id}
                {...tutor}
                onSelect={handleSelectTutor}
                isSelected={selectedTutor === tutor.id}
              />
            ))}
            {showForm && (
              <div className="w-full p-4">
                {selectedTutor === "custom" && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Sube un pdf con el temario que quieras y hazle preguntas
                      (solo PDFs máximo 100Mb)
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500 text-sm font-bold mb-2">
                    {errorMessage}
                  </div>
                )}
                <div className="flex items-center justify-center space-x-4">
                  <button
                    className="mt-4 btn btn-primary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleStartConversation}
                  >
                    Iniciar conversación
                    {loadingIniciarChat && (
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white-600"></div>
                    )}
                  </button>
                </div>
              </div>
            )}
            {!token && <SEOText />}
          </div>
        </>
      )}

      {showChat && (
        <>
          <button
            onClick={handleReturnToSelection}
            className="btn btn-primary mb-4"
          >
            Volver a seleccionar tutor
          </button>
          <Chat
            assistantData={assistantData}
            ref={chatRef}
            selectedTutor={selectedTutor}
          />
        </>
      )}
    </div>
  );
};

export { Chat, TutorCard, TutorsGallery };
