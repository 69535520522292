import React, { useEffect, useRef, useState } from "react";
import GoogleAuth from "./GoogleAuth";
import useAuth from "./useAuthToken";

const APIURL = `${process.env.REACT_APP_API_URL}/api/easyexams`;
const TURNSTILE_SITE_KEY = "0x4AAAAAAAkKxkBp6yJYqY5I"; // Tu clave de sitio

export default function Login() {
  const [token, clearToken] = useAuth();
  const turnstileRef = useRef(null);
  const [turnstileToken, setTurnstileToken] = useState(null);

  useEffect(() => {
    if (token) {
      window.location.href = "/";
    }

    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (turnstileRef.current && !turnstileRef.current.hasChildNodes()) {
        window.turnstile.render(turnstileRef.current, {
          sitekey: TURNSTILE_SITE_KEY,
          callback: function(token) {
            console.log("Turnstile token:", token);
            setTurnstileToken(token);
          },
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  async function signIn(e) {
    e.preventDefault();
    if (!turnstileToken) {
      alert("Por favor, complete el captcha.");
      return;
    }
    await sendDataToAPI(turnstileToken);
  }

  async function sendDataToAPI(turnstileToken) {
    const emailInput = document.getElementById("email");
    const disclaimerCheckbox = document.getElementById("disclaimer");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput.value)) {
      alert("Por favor, proporciona un correo electrónico válido.");
      return;
    }

    if (!disclaimerCheckbox.checked) {
      alert("Por favor, acepta el descargo de responsabilidad para continuar.");
      return;
    }

    try {
      const response = await fetch(APIURL + "/emailvalidate", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email: emailInput.value,
          'cf-turnstile-response': turnstileToken,
        }),
      });

      if (response.status !== 200) {
        const text = await response.text();
        alert(text || "Hubo un error al validar el email.");
        return;
      }

      alert("Email validado correctamente. Por favor, revisa tu correo electrónico.");
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error);
      alert("Hubo un error al realizar la solicitud POST a la API.");
    }
  }

  return (
    <div className="max-w-xl mx-auto my-12">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-6 font-medium text-gray-900 mb-2" id="modal-title">
            Inicio de Sesión
          </h1>
        </div>
        <p className="mt-4 text-sm text-gray-500">
          Ingresa tu correo electrónico para recibir un enlace de acceso o utiliza tu cuenta de Google para iniciar sesión de forma rápida y segura.
        </p>
        <form onSubmit={signIn}>
          <div className="flex items-center justify-center mb-2 mt-2">
            <label htmlFor="email" className="mb-2 hidden">
              Correo electrónico:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="input input-bordered w-full"
              placeholder="Correo electrónico"
            />
          </div>
          <div className="flex items-center text-xs mb-4">
            <input
              type="checkbox"
              id="disclaimer"
              name="disclaimer"
              required
              className="mr-2"
            />
            <label htmlFor="disclaimer">
              Acepto recibir correos electrónicos y los{" "}
              <a
                href="condiciones-de-uso.html"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 underline"
              >
                términos de uso del servicio
              </a>
            </label>
          </div>
          <div ref={turnstileRef} className="my-4"></div>
          <button type="submit" className="btn w-full mt-4" disabled={!turnstileToken}>
            Entrar
          </button>
          <hr className="my-4" />
          <GoogleAuth />
        </form>
      </div>
    </div>
  );
}