import * as pdfjsLib from "pdfjs-dist/webpack";
import { default as React, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import CurrentPath from "./CurrentPath";
import useAuth from "./useAuthToken";
import useCheckSubscription from "./useCheckSubscription";

const apiUrl = process.env.REACT_APP_API_URL;
const Summary = ({setShowNoTokenModal}) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Limpiar el texto para ocultar el área de texto
    setSummary("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Limpiar el archivo para ocultar el input de archivo
    setSummary("");
  };

  const handleSummarize = async () => {
    if (subscriptionType === "No Subscription") {
      window.location.href="/ver-precios"
      return;
    }

    if (!file && !text.trim()) {
      alert(
        "Por favor, sube un archivo o escribe algún texto antes de generar un resumen."
      );
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error al extraer el texto del PDF. Por favor, prueba con otro archivo."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Tipo de archivo no admitido. Sube una imagen o un archivo PDF.");
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/summary`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      if (data?.too_long) {
        setErrorMessage(
          "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: " +
            data?.total_tokens +
            " y el máximo son " +
            data?.max_tokens +
            " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching the summary.");
      }

      setSummary(data.summary);
    } catch (error) {
      console.error("Error summarizing text:", error);
      setErrorMessage(
        "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Generador de Resúmenes Inteligente</h2>
        <p className="mb-4 text-gray-600">
          Obtén resúmenes concisos y eficientes de tus documentos con nuestro Generador de Resúmenes. Sube un documento en PDF, varias imágenes o introduce texto directamente para recibir un resumen claro y preciso. Esta herramienta es ideal para estudiantes, profesionales y cualquier persona que necesite sintetizar información rápidamente.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Soporte para documentos en PDF y texto directo</li>
          <li className="mb-2">Resúmenes rápidos y precisos</li>
          <li className="mb-2">Ideal para diversas necesidades de información</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Fácil Uso y Flexibilidad</h2>
        <p className="mb-4 text-gray-600">
          Nuestro generador de resúmenes es extremadamente fácil de usar. Simplemente sube tu documento en PDF, imágenes o escribe el texto directamente en nuestro editor. La herramienta procesará el contenido y generará un resumen que captura los puntos clave de manera efectiva.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Interfaz intuitiva y fácil de navegar</li>
          <li className="mb-2">Procesamiento rápido de documentos</li>
          <li className="mb-2">Resumen generado en segundos</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Versatilidad para Diferentes Tipos de Documentos</h2>
        <p className="mb-4 text-gray-600">
          Nuestro generador de resúmenes es versátil y admite una amplia gama de documentos. Ya sea que estés trabajando con textos académicos, informes de trabajo o cualquier otro tipo de contenido, nuestra herramienta puede ayudarte a obtener un resumen preciso y útil.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Compatible con PDFs y múltiples formatos de imagen</li>
          <li className="mb-2">Adaptado para diferentes áreas y usos</li>
          <li className="mb-2">Perfecto para estudiantes, profesionales y más</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Beneficios del Generador de Resúmenes</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Ahorro de tiempo al sintetizar información</li>
          <li className="mb-2">Mejora en la comprensión de los temas clave</li>
          <li className="mb-2">Reducción de la sobrecarga de información</li>
          <li className="mb-2">Acceso rápido a los puntos más importantes</li>
          <li className="mb-2">Facilidad de uso en cualquier dispositivo</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Optimiza Tu Procesamiento de Información</h2>
        <p className="text-gray-600">
          Nuestro Generador de Resúmenes es la herramienta ideal para optimizar el procesamiento de información. Ya sea que necesites resumir material académico, documentos de trabajo o cualquier otro tipo de contenido, nuestra plataforma está diseñada para ayudarte a extraer los puntos clave de manera rápida y eficiente. ¡Comienza a utilizar el Generador de Resúmenes hoy mismo y transforma la manera en que manejas la información!
        </p>
      </section>
    </div>
  );
  

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Resumen de Texto"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📄 Generador de Resúmenes
        </h1>
        <p className="mb-6">
          Sube un documento en PDF o varias imágenes o puedes introducir texto
          directamente para obtener un resumen conciso y eficiente.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          Escribe un texto o sube un PDF
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Escribe aquí tu texto..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleSummarize} className="btn btn-primary mt-4">
          Generar Resumen
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Procesando...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {summary && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <ReactMarkdown
              rehypePlugins={[rehypeSanitize]}
              children={summary}
              disallowedElements={["pre"]}
              unwrapDisallowed
            />
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default Summary;
