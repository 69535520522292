import { Preferences } from "@capacitor/preferences";
import React, { useEffect, useState } from "react";
import { FaStripe, FaUserCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import useAuth from "./useAuthToken";
const apiUrl = process.env.REACT_APP_API_URL;

const UserProfile = ({setShowNoTokenModal}) => {
  const [user, setUser] = useState({
    email: "",
    dateSuscriptionFinish: 5,
    stripeLink: "",
    subscriptionType: "",
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [token, clearToken] = useAuth();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    Preferences.remove({ key: "token" }).then(()=> {
      window.location.href = "/iniciar-sesion";
    })
    //window.location.href = "https://www.fotoexamen.com/?logout=true";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "" ) return;
      if (!token) {
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Usa el token en la petición
        },
      });

      // Aquí haces la llamada a la API para obtener los datos del usuario usando el token
      // Suponiendo que la API responde con los datos en el formato { email, dateSuscriptionFinish, tripeLink }
      //const data = await fakeApiCall(token); // Reemplaza esto con tu llamada a la API real
      const dataToken = await response.json();
      let userData = {};
      if(dataToken?.email) localStorage.setItem("email", dataToken.email);
      //console.log(dataToken)

      if ("dateSuscriptionFinish" in dataToken) {
        userData = {
          email: dataToken.email,
          dateSuscriptionFinish: dataToken.dateSuscriptionFinish,
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      } else {
        userData = {
          email: dataToken.email,
          dateSuscriptionFinish: "",
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      }

      try {
        const subscriptionResponse = await fetch(
          `${apiUrl}/api/easyexams/checkpremium`,
          {
            method: "POST", // Asumiendo que este endpoint es GET
            headers: {
              Authorization: `Bearer ${token}`, // Usa el token en la petición
            },
          }
        );

        if (
          subscriptionResponse.ok &&
          subscriptionResponse.headers
            .get("Content-Type")
            ?.includes("application/json")
        ) {
          try {
            const jsonResponse = await subscriptionResponse.json();
            const subscriptionType = jsonResponse.subscriptionType; // Accede al tipo de suscripción de esta manera
            userData.subscriptionType = subscriptionType;
          } catch (error) {
            console.error("Error al analizar la respuesta JSON:", error);
          }
        } else {
          console.error(
            "La respuesta no es JSON o el servidor devolvió un error:",
            subscriptionResponse.status,
            await subscriptionResponse.text()
          );
        }

        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [token, setShowNoTokenModal]);

  // // Simula una llamada a la API
  // const fakeApiCall = (token) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve({
  //         email: 'usuario@example.com',
  //         dateSuscriptionFinish:4 ,
  //         stripeLink: 'https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII',
  //       });
  //     }, 1000);
  //   });
  // };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Cargando...
      </div>
    );
  }

  return (
    <div className="container mx-auto p-5 mb-20 mt-20">
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-4 py-5 sm:p-6 bg-gradient-to-r from-blue-500 to-indigo-600">
          <h3 className="text-lg leading-6 font-medium text-white">
            <FaUserCircle className="inline-block h-6 w-6 mr-2" />
            Perfil del Usuario
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Email</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                {user.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Datos</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                <Link to="/bienvenida" className="btn btn-neutral w-full">
                  Editar mis datos
                </Link>
              </dd>
            </div>
            {user.subscriptionType !== "No Subscription" && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium">
                  ¿Que te parece FotoExamen?
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <button
                    className="btn btn-accent w-full text-white"
                    onClick={() =>
                      document.getElementById("my_modal_3").showModal()
                    }
                  >
                    ⭐️ Déjanos tu valoración
                  </button>
                </dd>
              </div>
            )}
            <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Tipo de Suscripción</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.subscriptionType === "No Subscription" ? (
                  <Link to="/ver-precios" className="btn btn-primary">
                    ¡Suscríbete ahora!
                  </Link>
                ) : (
                  user.subscriptionType || "No disponible"
                )}
              </dd>
            </div>
            {user.subscriptionType !== "No Subscription" && (<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Fin de la suscripción</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.dateSuscriptionFinish}
              </dd>
            </div>)}
            <div className="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Gestión de Suscripción</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0 flex flex-col ">
                <a
                  href="https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII" // Cambiar por el enlace real
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FaStripe className="mr-2" />
                  Gestionar mi suscripción
                </a>
                <p className="max-w-2xl mt-2">
                  ¿No cumple fotoexamen con tus ejercicios? Recuerda que tenemos
                  <a
                    href="https://www.fotoexamen.com/precios.html"
                    title="Precios"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    otros planes de subscripción{" "}
                  </a>{" "}
                  con modelos IA más potentes que pueden ayudarte en problemas
                  más complejos.
                </p>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium">Salir</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <button className="btn btn-neutral" onClick={handleLogout}>
                  Cerrar Sesión
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
