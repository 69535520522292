import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { Link, useLocation } from "react-router-dom";
import rehypeSanitize from "rehype-sanitize";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import useAuth from "./useAuthToken.js";
import useCheckSubscription from "./useCheckSubscription.js";

const apiUrl = process.env.REACT_APP_API_URL;

const ExamCorrector = ({setShowNoTokenModal}) => {
  const [image, setImage] = useState(null);
  const [imagebin, setImagebin] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [examResult, setExamResult] = useState("");
  const [isStripeModalVisible, setIsStripeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const location = useLocation();
  const stripeModalRef = useRef(null);
  const socketRef = useRef(null);
  const messageCount = useRef(0);

  const connectSocket = (onFormResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("form_response", (data) => {
      onFormResponse(data);
    });

    socket.on("form_end", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    setExamData({
      ocrText: ocrText,
      response: examResult,
      lastOCR: new Date(),
    });
  }, [ocrText, examResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        stripeModalRef.current &&
        !stripeModalRef.current.contains(event.target)
      ) {
        setIsStripeModalVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = async (e) => {
    if (subscriptionType === "No Subscription") {
      window.location.href="/ver-precios"
      return;
    }

    setIsLoadingOcr(true);
    const selectedFile = e.target.files[0];

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoadingOcr(false);
      return;
    }

    try {
      if (selectedFile.type === "application/pdf") {
        setImage(null);
        setImagebin(null);
        setPdfUrl(selectedFile);

        const pdf = await pdfjsLib.getDocument(
          URL.createObjectURL(selectedFile)
        ).promise;

        if (pdf.numPages > 45) {
          setIsLoading(false);
          setIsLoadingOcr(false);
          alert(
            "El PDF de tu exámen debe tener menos de 45 páginas ya que es demasiado grande para procesar."
          );
          return;
        }

        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
        setOcrText(textContent);
        setPdfPreview(URL.createObjectURL(selectedFile));
        setIsLoadingOcr(false);
      } else if (selectedFile.type.startsWith("image/")) {
        setPdfUrl(null);
        setPdfPreview(null);
        setImage(URL.createObjectURL(selectedFile));
        setImagebin(selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            setOcrText(responseData.texts);
          }
        } else {
          setIsLoadingOcr(false);
          alert(
            "No se pudo detectar el texto en la imagen.  Asegúrese de que su imagen tenga un tamaño inferior a 20 MB y tenga uno de los siguientes formatos: .png, .jpeg, .gif o .webp."
          );
        }
      } else {
        alert("Tipo de archivo no admitido. Sube una imagen o un archivo PDF.");
        setIsLoadingOcr(false);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
      setIsLoadingOcr(false);
    }
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Reintentar después de 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleCorrectExam = async () => {
    setIsLoading(true);
    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setExamResult(data?.form_response);
        }
        if (data?.thinking){
          setExamResult(data?.thinking);
        }
      },
      (data) => {
        setExamResult(data?.form_end);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
    } catch (error) {
      alert(
        "No se pudo establecer la conexión. Por favor, vuelve a intentarlo."
      );
      setIsLoading(false);
      return;
    }

    const data = {
      token: token,
      form: {
        ocrText: ocrText,
      },
      files: {},
    };

    if (imagebin) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.image = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(imagebin);
    } else if (pdfUrl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.pdf = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(pdfUrl);
    } else {
      socketRef.current.emit("form_correct", data);
    }
  };

  const showStripeModal = () => {
    setIsStripeModalVisible(true);
  };

  const toggleTipsModal = () => {
    setShowTipsModal(!showTipsModal);
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Corrige tu Examen con IA</h2>
        <p className="mb-4 text-gray-600">
          Simplifica y mejora el proceso de corrección de exámenes con nuestra innovadora inteligencia artificial. Sube una imagen o un PDF de tu examen resuelto y deja que nuestra IA personalizada lo corrija por ti. Edita cualquier error detectado en el texto y presiona "corregir examen" cuando estés listo. Obtén resultados precisos y descarga el examen corregido en formato PDF.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Sube imágenes o documentos PDF de exámenes resueltos</li>
          <li className="mb-2">Corrección precisa y rápida con IA</li>
          <li className="mb-2">Opción para editar texto antes de la corrección final</li>
          <li className="mb-2">Resultados disponibles para ver y descargar en PDF</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Cómo Funciona</h2>
        <p className="mb-4 text-gray-600">
          Utilizar nuestro sistema de corrección de exámenes con IA es sencillo y efectivo. Sube una imagen o un documento PDF de tu examen resuelto. La IA analizará y corregirá el contenido automáticamente. Puedes revisar y editar cualquier error detectado antes de confirmar la corrección final. Una vez listo, podrás ver los resultados y descargar el examen corregido en formato PDF.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Sube tu examen en formato imagen o PDF</li>
          <li className="mb-2">Deja que la IA analice y corrija el contenido</li>
          <li className="mb-2">Edita el texto si es necesario</li>
          <li className="mb-2">Obtén resultados precisos y descárgalos en PDF</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Ventajas de Corregir con IA</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Ahorro de tiempo en la corrección de exámenes</li>
          <li className="mb-2">Precisión y consistencia en las correcciones</li>
          <li className="mb-2">Reducción de errores humanos</li>
          <li className="mb-2">Facilidad para editar y ajustar correcciones</li>
          <li className="mb-2">Resultados rápidos y accesibles en formato PDF</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Aplicaciones del Corrector de Exámenes con IA</h2>
        <p className="mb-4 text-gray-600">
          Nuestro corrector de exámenes con IA es una herramienta versátil, útil en diversos contextos educativos y profesionales. Ya sea que seas estudiante, profesor o profesional que necesita verificar respuestas en documentos, esta herramienta te ofrece una solución eficiente y precisa para la corrección de exámenes.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Estudiantes que desean verificar sus respuestas</li>
          <li className="mb-2">Profesores que necesitan corregir exámenes rápidamente</li>
          <li className="mb-2">Profesionales que requieren revisar documentos con precisión</li>
          <li className="mb-2">Cualquiera que necesite una corrección rápida y precisa</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Mejora tu Proceso de Corrección con IA</h2>
        <p className="text-gray-600">
          Nuestro corrector de exámenes con IA está diseñado para transformar tu forma de corregir y revisar exámenes. Olvídate del tedioso proceso manual y aprovecha la precisión y rapidez de nuestra inteligencia artificial. Sube tu examen resuelto, edita cualquier error detectado y obtén resultados exactos y descargables en PDF. ¡Comienza a usar nuestro corrector de exámenes con IA hoy mismo y mejora tu eficiencia en la corrección de exámenes!
        </p>
      </section>
    </div>
  );
  

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text="Corrige tu examen" />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            ✍🏻 Corrige tu examen
          </h1>
          <p>
            Utiliza nuestra inteligencia artificial personalizada para corregir
            todos tus exámenes, sube una imagen o un PDF de tu examen resuelto.
            Edita el texto detectado si encuentras algún error y presiona
            corregir examen cuando estés listo. Puedes ver el resultado o
            descargarlo en formato PDF.
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">Sube tu examen resuelto:</h2>
          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              capture="environment"
              className="hidden"
              onChange={handleImageChange}
              id="photo-capture"
            />
            <label
              htmlFor="photo-capture"
              className="btn btn-primary flex justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded cursor-pointer transition ease-in-out duration-300"
            >
              <FontAwesomeIcon icon={faCamera} className="fa-lg mr-2" />
              Tomar Foto
            </label>
          </div>

          <div className="flex justify-center items-center">
            <input
              type="file"
              accept="image/*,application/pdf"
              className="file-input w-full max-w-xs"
              onChange={handleImageChange}
            />
          </div>
        </div>

        {(image || pdfPreview) && (
          <div className="flex items-center justify-center mb-16">
            <dialog id="previewModal" className="modal">
              <div className="modal-box">
                {image && (
                  <img
                    src={image}
                    alt="Uploaded exam"
                    className="max-w-full h-auto"
                  />
                )}
                {pdfPreview && (
                  <iframe
                    src={pdfPreview}
                    title="PDF Preview"
                    className="w-full h-96"
                  ></iframe>
                )}
                <div className="modal-action">
                  <button
                    className="btn"
                    onClick={() =>
                      document.getElementById("previewModal").close()
                    }
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </dialog>
            <div
              onClick={() =>
                document.getElementById("previewModal").showModal()
              }
              className="btn mt-3 rounded h-64 w-full object-cover object-top flex items-center justify-center overflow-hidden"
              style={{ background: "#f0f0f0" }}
            >
              {image ? (
                <img
                  src={image}
                  alt="Uploaded exam"
                  className="max-w-full h-auto"
                />
              ) : (
                <FaFilePdf size={64} />
              )}
            </div>
          </div>
        )}

        {ocrText !== "" && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Texto detectado:</h2>
            <textarea
              className="w-full h-64 border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line"
              value={ocrText}
              onChange={(e) => setOcrText(e.target.value)}
            ></textarea>
          </div>
        )}

        {isLoadingOcr && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Detectando el texto del archivo o imagen...
            </p>
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              Corrigiendo examen con nuestra IA avanzada...
            </p>
          </div>
        )}
        <div className="mb-8">
          <div className="flex justify-start items-center space-x-4 flex-col md:flex-row">
            <button
              className={`btn btn-primary flex my-4 justify-center items-center bg-green-200 hover:bg-green-300 text-green-700 font-semibold py-3 px-6 rounded transition ease-in-out duration-300 ${
                !ocrText.trim() ||
                (isLoading && "opacity-50 cursor-not-allowed")
              }`}
              onClick={handleCorrectExam}
              disabled={!ocrText.trim() || isLoading}
            >
              Corregir Examen
            </button>
            <button
              onClick={toggleTipsModal}
              className="btn font-bold py-1 px-3 text-sm rounded ml-2"
            >
              Recomendaciones de uso
            </button>
          </div>

          {examResult !== "" && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">
                Resultado de la corrección:
              </h2>
              <button
                onClick={() => downloadPDF(examData)}
                className="mb-2 bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded transition ease-in-out duration-300 flex items-center justify-center mt-4"
              >
                <FaFilePdf size={24} className="mr-2" /> Descargar PDF
              </button>
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <ReactMarkdown
                  rehypePlugins={[rehypeSanitize]}
                  children={examResult}
                  disallowedElements={["pre"]}
                  unwrapDisallowed
                />
              </div>
            </div>
          )}
        </div>
        {isStripeModalVisible && (
          <div
            id="stripeModal"
            ref={stripeModalRef}
            className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
              <div className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
                  <h2 className="text-lg sm:text-xl font-bold mb-4">
                    Gastaste tus créditos gratis
                  </h2>
                  <p className="mb-4 text-sm sm:text-base">
                    ¿Quieres seguir usando Corrección de Examen? Descubre los
                    beneficios increíbles de nuestros planes premium:
                  </p>
                  <ul className="list-disc list-inside text-left mb-4 text-sm sm:text-base">
                    <li className="mb-2">
                      🚀 Acceso ilimitado a correcciones de exámenes.
                    </li>
                    <li className="mb-2">
                      💡 Soporte prioritario para ayudarte cuando más lo
                      necesitas.
                    </li>
                    <li className="mb-2">
                      🔐 Procesamiento prioritario de tus solicitudes.
                    </li>
                    <li className="mb-2">
                      🎓 Herramientas avanzadas para un aprendizaje más eficaz.
                    </li>
                    <li className="mb-2">
                      💼 Recursos adicionales para prepararte para tus exámenes.
                    </li>
                  </ul>
                  <p className="mb-4 font-bold text-sm sm:text-base">
                    ¡Invierte en tu éxito académico, elige un plan premium hoy y
                    siente la diferencia!
                  </p>
                  <button
                    id="view-prices"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300 ease-in-out shadow-lg transform hover:scale-105"
                    onClick={showStripeModal}
                  >
                    Ver planes 💲
                  </button>
                </div>
              </div>

              <button onClick={showStripeModal}>Ver planes 💲</button>
            </div>
          </div>
        )}

        {showTipsModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  Tips para usar la plataforma
                </p>
                <div className="cursor-pointer z-50" onClick={toggleTipsModal}>
                  <svg
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
                  </svg>
                </div>
              </div>
              <ul className="list-disc list-inside text-left text-md lg:text-lg mb-4 leading-relaxed">
                <li>
                  Fotografía clara: Asegúrate de tomar fotos claras donde el
                  texto esté completamente horizontal. Esto mejora la precisión
                  con la que la plataforma puede interpretar y procesar la
                  información. Además la imagen no debe superar los 20Mb y los
                  formatos aceptados son: 'png', 'jpeg', 'gif', 'webp'
                </li>
                <li>
                  Repetir el proceso: No dudes en corregir varias veces la misma
                  foto. A veces, la plataforma puede ofrecer resultados
                  ligeramente diferentes, por lo que es útil hacer varias
                  pruebas para obtener la mejor respuesta.
                </li>
                <li>
                  Razonamiento en matemáticas: En exámenes de matemáticas, es
                  crucial no solo fiarse de las correcciones automáticas. Toma
                  los razonamientos que te ofrece la plataforma y luego verifica
                  los cálculos manualmente para asegurarte de que son correctos.
                </li>
                <li>
                  Usa los{" "}
                  <Link
                    to="/tutores"
                    className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out"
                  >
                    tutores
                  </Link>
                  : Si tienes dudas sobre alguna respuesta, aprovecha la sección
                  de tutores de la plataforma. Puedes subir el ejercicio en
                  cuestión y hacer todas las preguntas que necesites para
                  aclarar tus dudas y entender mejor el material.
                </li>
              </ul>
              <div className="flex justify-end pt-2">
                <button
                  className="px-4 bg-blue-500 hover:bg-blue-700 text-white p-3 rounded-lg text-sm lg:text-base transition duration-300 ease-in-out"
                  onClick={toggleTipsModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default ExamCorrector;
