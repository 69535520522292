import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilePdf } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
import CurrentPath from "./CurrentPath";
import "./ExamList.css";

const apiUrl = process.env.REACT_APP_API_URL;

function removeMarkdown(markdown) {
  return (
    markdown
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, "")
      // Remove inline code
      .replace(/`[^`]*`/g, "")
      // Remove images
      .replace(/!\[.*?\]\(.*?\)/g, "")
      // Remove links
      .replace(/\[([^\]]+)\]\((.*?)\)/g, "$1")
      // Remove blockquotes
      .replace(/^>\s+/gm, "")
      // Remove headers
      .replace(/^#{1,6}\s+(.*)$/gm, "$1")
      // Remove horizontal rules
      .replace(/^(-{3,}|_{3,}|\*{3,})$/gm, "")
      // Remove bold and italic
      .replace(/(\*\*|__)(.*?)\1/g, "$2")
      .replace(/(\*|_)(.*?)\1/g, "$2")
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, "$1")
      // Remove lists
      .replace(/^\s*([-+*]|(\d+\.))\s+/gm, "")
  );
}

export const downloadPDF = async (exam, index) => {
  if (localStorage.getItem("token")) {
    console.log("Downloading PDF", exam, index);

    const doc = new jsPDF();

    // Configuración inicial del documento
    doc.setFont("helvetica", "normal");

    // URL del logo
    const logoUrl = "/logo512.png";

    // Cargar y agregar el logo en el centro
    // El tamaño del logo se ha ajustado para una mejor calidad
    doc.addImage(logoUrl, "PNG", 85, 10, 40, 40, undefined, "FAST");

    // Ajustar la posición Y después del logo
    let yPosition = 60;

    // Encabezado debajo del logo
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text(
      "Generado por fotoexamen.com",
      105,
      yPosition,
      null,
      null,
      "center"
    );
    yPosition += 10; // Espacio después del encabezado

    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition, 200, yPosition);

    yPosition += 10; // Espacio después de la línea

    // Altura máxima de la página
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

    // Función auxiliar para agregar texto y controlar la adición de páginas
    const addText = (
      text,
      xPosition,
      initialYPosition,
      fontSize,
      isTitle = false
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, 180); // Ajusta el ancho según sea necesario
      lines.forEach((line, index) => {
        if (initialYPosition > pageHeight - 10) {
          // Comprobar si se necesita una nueva página
          doc.addPage();
          initialYPosition = 20; // Restablecer la posición Y al margen superior de la nueva página
        }
        if (isTitle && index === 0) {
          doc.setFont("helvetica", "bold");
          doc.setTextColor(60, 60, 60); // Un gris oscuro para el título
        } else {
          doc.setFont("helvetica", "normal");
          doc.setTextColor(40);
        }
        doc.text(line, xPosition, initialYPosition);
        initialYPosition += fontSize * 0.5; // Espaciado de línea ajustado
        if (isTitle && index === 0) {
          initialYPosition += 2; // Espacio adicional después del título
        }
      });
      return initialYPosition;
    };

    // Añadir la imagen
    const fetchImage = async (index) => {
      const token = localStorage.getItem("token");
      console.log("Fetching image", index, token);

      const url = apiUrl + `/api/easyexams/image?image=` + index;
      return fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        if (!response.ok) {
          return; // Salimos temprano de la función para evitar procesar más la respuesta
        }
        return response.json();
      });
    };

    const responsedata = await fetchImage(index);
    let imageBase64 = responsedata?.image;

    if (imageBase64) {
      // Suponiendo que la imagen es de tipo JPEG
      // Obtener las dimensiones de la imagen para ajustarlas a los márgenes de la página
      const imgProps = doc.getImageProperties(imageBase64);
      const pdfWidth = doc.internal.pageSize.getWidth() - 20; // Ancho del PDF menos márgenes
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width; // Calcular la altura manteniendo la relación de aspecto

      // Asegurarse de que la imagen no sea más alta que la página
      if (pdfHeight + yPosition > doc.internal.pageSize.getHeight()) {
        // Ajustar la altura y calcular el nuevo ancho
        const maxHeight = doc.internal.pageSize.getHeight() - yPosition - 10; // 10 para el margen inferior
        const newWidth = (imgProps.width * maxHeight) / imgProps.height;
        doc.addImage(imageBase64, "JPEG", 10, yPosition, newWidth, maxHeight);
      } else {
        doc.addImage(imageBase64, "JPEG", 10, yPosition, pdfWidth, pdfHeight);
      }

      yPosition += pdfHeight + 10;
    } else {
      // Añadir el texto OCR
      const formattedOcrText = `Enunciado:\n${formatText(exam?.ocrText)}`;
      yPosition = addText(formattedOcrText, 10, yPosition, 12, true) + 6;
    }

    // Añadir el texto de la respuesta
    const borrarmarkdown = removeMarkdown(formatText(exam?.response));
    const formattedResponseText = `Resultado:\n${borrarmarkdown}`;
    addText(formattedResponseText, 10, yPosition, 12, true);

    // Guardar el PDF con un nombre de archivo significativo
    const examDate = new Date(exam?.lastOCR).toISOString().slice(0, 10);
    if (index) {
      doc.save(`Examen-${examDate}-ID${index + 1}.pdf`);
    } else {
      doc.save(`Examen-${examDate}.pdf`);
    }
  } else {
    console.log("Downloading PDF mobile: ", exam, index);

    const styles = StyleSheet.create({
      page: { flexDirection: "column", backgroundColor: "#FFFFFF" },
      logo: { width: 40, height: 40, margin: "20px auto" },
      header: { textAlign: "center", margin: 10 },
      line: {
        marginTop: 5,
        marginBottom: 10,
        borderWidth: 0.5,
        borderColor: "#000",
      },
      text: { margin: 10, fontSize: 12 },
      title: { fontSize: 14, fontWeight: "bold", margin: 10, color: "#404040" },
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            src="https://app.fotoexamen.com/logo512.png"
            style={styles.logo}
          />
          <Text style={styles.header}>Generado por fotoexamen.com</Text>
          <View style={styles.line} />
          <Text style={styles.title}>{`Enunciado:\n${exam.ocrText}`}</Text>
          <Text style={styles.text}>{`Resultado:\n${exam.response}`}</Text>
        </Page>
      </Document>
    );

    try {
      const blob = await pdf(<MyDocument />).toBlob();
      try {
        saveAs(
          blob,
          `Examen-${new Date(exam.lastOCR).toISOString().slice(0, 10)}-ID.pdf`
        );
      } catch (error) {
        console.error("Error saving file in chrome:", error);
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        const path = `Download/Examen-${new Date(exam.lastOCR)
          .toISOString()
          .slice(0, 10)}.pdf`;
        let file = await Filesystem.writeFile({
          path: path,
          data: base64data,
          directory: Directory.ExternalStorage,
        });
        console.log("File saved successfully");
        try {
          await FileOpener.openFile({
            path: file.uri,
            mimeType: "application/pdf",
          });
        } catch (openError) {
          console.error("Error opening file:", openError);
        }
      };
    } catch (error) {
      console.error("Error generating or saving PDF:", error);
    }
  }
};

export const formatText = (text) => {
  if (typeof text !== "string") {
    console.error("formatText was called with a non-string argument", text);
    return text; // Return an empty string or handle the error as appropriate
  }
  return text.replace(/\\r\\n|\\r|\\n/g, "\n");
};

const formatTextWithNewlines = (text) => {
  return text.split("\n").map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
};

const ExamList = ({ setShowNoTokenModal }) => {
  const [exams, setExams] = useState([]);
  const [currentExams, setCurrentExams] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const examsPerPage = 10;

  const fetchExams = async () => {
    setLoading(true);
    setError(null);
    let token = localStorage.getItem("token");
    if (!token) {
      const ret = await Preferences.get({ key: "token" });
      if (ret?.value) {
        token = ret?.value;
      }
    }

    try {
      if (!token) {
        console.error("No authentication token found.");
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(`${apiUrl}/api/easyexams/exams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      setExams(data.sort((a, b) => new Date(b.lastOCR) - new Date(a.lastOCR)));
      setCurrentPage(1);
    } catch (error) {
      setError("No hay exámenes en el historial.");
      setExams([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExams();
  }, []);

  useEffect(() => {
    const indexOfLastExam = currentPage * examsPerPage;
    const indexOfFirstExam = indexOfLastExam - examsPerPage;
    setCurrentExams(exams.slice(indexOfFirstExam, indexOfLastExam));
  }, [currentPage, exams]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const targetDate = date.toLocaleDateString("en-CA"); // 'en-CA' uses the format yyyy-mm-dd
      const filteredExams = exams.filter((exam) => {
        const examDate = new Date(exam.lastOCR).toLocaleDateString("en-CA");
        return examDate === targetDate;
      });
      setExams(filteredExams);
      setCurrentPage(1);
    } else {
      fetchExams(); // Reload all exams if date is cleared
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(exams.length / examsPerPage);

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Historial de exámenes"} />
      <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
        🖇️ Historial de exámenes resueltos
      </h1>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        isClearable
        placeholderText="Seleccionar una fecha"
        className="p-2 w-full rounded bg-white text-black custom-datepicker"
      />
      {loading && <p className="mb-4">Cargando...</p>}
      {error && <p className="mb-4 text-black">{error}</p>}
      {!loading &&
        !error &&
        currentExams.map((exam, index) => (
          <div
            key={index}
            className="collapse collapse-plus bg-base-200 rounded-box shadow my-2"
          >
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Examen "
              {exam.ocrText.split(" ").slice(0, 2).join(" ").toLowerCase()}" -{" "}
              {new Date(exam.lastOCR).toLocaleDateString("es-ES")} -{" "}
              {new Date(exam.lastOCR).toLocaleTimeString("es-ES")}
            </div>
            <div className="collapse-content bg-base-100 text-black p-4">
              <div className="font-semibold">Enunciado:</div>
              <br />
              <p>{exam.ocrText}</p>
              {/* separator */}
              <hr className="my-4" />
              <div className="font-semibold mt-2">Resultado:</div>
              <br />
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <ReactMarkdown
                  rehypePlugins={[rehypeSanitize]}
                  children={exam?.response}
                  disallowedElements={["pre"]}
                  unwrapDisallowed
                  //children={exam?.response}
                />
              </div>
              <button
                className="btn btn-primary mt-4"
                onClick={() =>
                  downloadPDF(exam, exam?.image ? exam?.image : "")
                }
              >
                <FaFilePdf className="mr-2" /> Descargar PDF
              </button>
            </div>
          </div>
        ))}
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Anterior
        </button>
        <p className="text-lg">
          Página {currentPage} de {totalPages}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn btn-primary"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};
export default ExamList;
