import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useState } from "react";
import CurrentPath from "./CurrentPath";
import useAuth from "./useAuthToken";
import useCheckSubscription from "./useCheckSubscription";
const apiUrl = process.env.REACT_APP_API_URL;

const Highlight = ({setShowNoTokenModal}) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error } = useCheckSubscription();
  const [token, clearToken] = useAuth();

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText(""); // Limpiar el texto para ocultar el área de texto
    setHighlightedText("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null); // Limpiar el archivo para ocultar el input de archivo
    setHighlightedText("");
  };

  const handleHighlight = async () => {
    if (!file && !text.trim()) {
      alert(
        "Por favor, sube un archivo o escribe algún texto antes de subrayar."
      );
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert(
        "Necesitas una suscripción para usar el servicio."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      if (file.type === "application/pdf") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
            .promise;
          let textContent = "";
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            text.items.forEach((item) => {
              textContent += item.str + " ";
            });
          }
          pdftext = textContent;
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(
            "Error al extraer el texto del PDF. Por favor, prueba con otro archivo."
          );
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/")) {
        formData.append("files", file);
      } else {
        alert("Tipo de archivo no admitido. Sube una imagen o un archivo PDF.");
        setIsLoading(false);
        return;
      }
    }

    if (text) {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/highlight`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: " +
            data?.total_tokens +
            " y el máximo son " + data?.max_tokens + " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching highlighted text.");
      }

      // unescape " characters that were written like \"
      data.highlight = data.highlight.replace(/\\\"/g, '"');

      setHighlightedText(data.highlight);
    } catch (error) {
      console.error("Error highlighting text:", error);
      setErrorMessage(
        "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const SEOText = () => (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Subrayador de Texto con IA</h2>
        <p className="mb-4 text-gray-600">
          Facilita tu proceso de estudio con nuestro Subrayador de Texto con IA. Sube un documento en PDF, varias imágenes o introduce texto directamente para recibir un subrayado automatizado que te ayudará a identificar rápidamente las ideas principales, datos importantes, definiciones y fechas clave. Esta herramienta está diseñada para estudiantes y profesionales que buscan optimizar su tiempo y mejorar su comprensión del material.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Compatible con PDFs, imágenes y texto directo</li>
          <li className="mb-2">Subrayado automatizado y eficiente</li>
          <li className="mb-2">Ideal para mejorar técnicas de estudio y revisión</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Esquema de Colores Inteligente</h2>
        <p className="mb-4 text-gray-600">
          Nuestro Subrayador de Texto con IA utiliza un esquema de colores inteligente para ayudarte a categorizar y diferenciar la información clave de tu material de estudio. El sistema emplea colores específicos para distintos tipos de contenido, permitiéndote enfocarte en lo más relevante de manera efectiva.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2"><span className="text-pink-500">Rosa</span> para ideas principales</li>
          <li className="mb-2"><span className="text-green-500">Verde</span> para datos importantes</li>
          <li className="mb-2"><span className="text-blue-500">Azul</span> para definiciones</li>
          <li className="mb-2"><span className="text-red-500">Rojo</span> para fechas clave</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Cómo Funciona</h2>
        <p className="mb-4 text-gray-600">
          Usar nuestro Subrayador de Texto con IA es muy sencillo. Solo necesitas subir tu documento en PDF, imágenes o introducir el texto directamente en nuestro editor. La IA procesará el contenido y aplicará el subrayado según el esquema de colores predefinido. Este proceso te permitirá identificar rápidamente las partes más importantes de tu material de estudio.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Sube tu documento o imágenes de texto</li>
          <li className="mb-2">Deja que la IA analice y subraye el contenido</li>
          <li className="mb-2">Recibe un documento subrayado listo para revisar</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Ventajas del Subrayado con IA</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Ahorro de tiempo al estudiar</li>
          <li className="mb-2">Mejora en la retención de información</li>
          <li className="mb-2">Identificación rápida de puntos clave</li>
          <li className="mb-2">Aumento en la eficiencia de la revisión</li>
          <li className="mb-2">Reducción del esfuerzo manual en el subrayado</li>
        </ul>
      </section>
  
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Aplicaciones del Subrayador de Texto con IA</h2>
        <p className="mb-4 text-gray-600">
          El Subrayador de Texto con IA es una herramienta versátil que puede ser utilizada en una variedad de contextos académicos y profesionales. Ya sea que estés preparando un examen, realizando una investigación o simplemente revisando un documento importante, esta herramienta te permitirá enfocarte en lo más relevante de manera rápida y eficiente.
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li className="mb-2">Estudiantes en preparación de exámenes</li>
          <li className="mb-2">Profesionales revisando informes</li>
          <li className="mb-2">Investigadores analizando textos académicos</li>
          <li className="mb-2">Cualquiera que necesite optimizar su proceso de estudio</li>
        </ul>
      </section>
  
      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">Mejora tu Estudio con Nuestro Subrayador de Texto con IA</h2>
        <p className="text-gray-600">
          Nuestro Subrayador de Texto con IA está diseñado para transformar tu manera de estudiar y procesar información. Aprovecha esta innovadora herramienta para identificar y concentrarte en las partes más importantes de tu material de estudio. Ya sea que estés en la escuela, en la universidad o en el trabajo, nuestro subrayador con IA te ayudará a maximizar tu eficiencia y efectividad. ¡Comienza a usarlo hoy mismo y descubre una nueva forma de estudiar!
        </p>
      </section>
    </div>
  );
  
  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-20">
      <CurrentPath text={"Texto Subrayado"} />
      <div className="container max-w-4xl mx-auto p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📝 Subrayador de texto
        </h1>
        <p>
          Sube un documento en PDF o varias imágenes o puedes introducir texto
          directamente para obtener un subrayado que te ayude a estudiar.
        </p>
        <p className="mb-6 mt-2">
          Se usa el siguiente esquema de colores: rosa para ideas principales,
          verde para datos importantes, azul para definiciones y rojo para
          fechas clave.
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {" "}
          Escribe un texto o sube un PDF{" "}
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Escribe aquí tu texto..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button onClick={handleHighlight} className="btn btn-primary mt-4">
          Subrayar Texto
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">Procesando archivos...</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {highlightedText && (
          <div
            className="p-4 mt-4 rounded-box text-lg border border-base-300"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          ></div>
        )}
      </div>
      {!token && <SEOText />}
    </div>
  );
};

export default Highlight;
