import React from 'react'
import { Link } from 'react-router-dom'

export default function ModalNoToken({setShowNoTokenModal}) {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg border">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">Parece que no tienes acceso</p>
          <div
            className="cursor-pointer z-50 btn btn-ghost"
            onClick={() => setShowNoTokenModal(false)}
          >
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
            </svg>
          </div>
        </div>
        <p>
          Para utilizar esta herramienta, adquire uno de <a href="https://www.fotoexamen.com/precios.html" title="precios" className='underline'>nuestros planes</a> disponibles o ya si dispones de uno por favor inicia sesión en{" "}
          <a href="https://www.fotoexamen.com" title="Iniciar sesión">
            www.fotoexamen.com
          </a>{" "}.
        </p>
        <div className="flex justify-end pt-2 mt-4">
          <Link
            className="px-4 btn btn-ghost"
            to="/iniciar-sesion"
            onClick={() => setShowNoTokenModal(false)}
          >
            INICIAR SESION
          </Link>
          <button
            className="px-4 btn btn-primary"
            onClick={() =>
              (window.location.href = "/ver-precios")
            }
          >
            VER PRECIOS
          </button>
        </div>
      </div>
    </div>
  )
}
