import React from "react";
import useCheckSubscription from "./useCheckSubscription";

export default function Footer() {
  const { subscriptionType, loading, error } = useCheckSubscription();

  return (
    <>
      <footer className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-10">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Enlaces rápidos</h3>
            <ul>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com">Inicio</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/contacto.html">Contacto</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/precios.html" title="Precios">
                  Precios
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Legal</h3>
            <ul>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/politica-de-datos-y-privacidad.html">
                  Políticas de privacidad
                </a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/condiciones-de-uso.html">Condiciones de uso</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/aviso-legal.html">Aviso legal</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/politica-de-cookies.html">Política de Cookies</a>
              </li>
              <li className="my-2 hover:underline">
                <a href="https://www.fotoexamen.com/pagos.html" title="Pagos">
                  Pagos
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Síguenos</h3>
            <div className="social-icons flex flex-col">
              <a
                href="https://twitter.com/fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Twitter"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-twitter"></i>
                Twitter
              </a>
              <a
                href="https://www.tiktok.com/@fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Tiktok"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-tiktok"></i>
                Tiktok
              </a>
              <a
                href="https://www.instagram.com/fotoexamen"
                rel="noreferrer"
                target="_blank"
                title="Instagram"
                className="hover:text-blue-400 transition-colors duration-300"
              >
                <i className="fab fa-instagram"></i>
                Instagram
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h3 className="text-xl font-semibold mb-4">Contacto</h3>
            <p className="mb-4">
              <a
                href="mailto:info@fotoexamen.com"
                rel="noreferrer"
                className="underline hover:text-blue-400 transition-colors duration-300"
                target="_blank"
                title="Email"
              >
                info@fotoexamen.com
              </a>
            </p>
            {subscriptionType !== "No Subscription" && (<button
              className="btn btn-accent text-white"
              onClick={() => document.getElementById("my_modal_3").showModal()}
            >
              ⭐️ Déjanos tu valoración
            </button>)}
            <dialog id="my_modal_3" className="modal">
              <div className="modal-box">
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-black">
                    ✕
                  </button>
                </form>
                <script
                  type="text/javascript"
                  src="https://widget.senja.io/js/iframeResizer.min.js"
                ></script>
                <iframe
                  id="senja-collector-iframe"
                  src="https://senja.io/p/foto-examen/r/5pPUbj?mode=embed&nostyle=true"
                  allow="camera;microphone"
                  title="Senja form"
                  frameBorder="0"
                  scrolling="no"
                  width="100%"
                  height="700"
                ></iframe>
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button className="btn btn-md btn-ghost text-black">
                    Cerrar
                  </button>
                </form>
              </div>
            </dialog>
          </div>
        </div>
        <div className="copyright mt-12 text-center text-white">
          © 2024 FotoExamen. Todos los derechos reservados.
        </div>
      </footer>
    </>
  );
}
