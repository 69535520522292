import React from "react";
import FAQ from "./FAQ";
import Precios from "./Precios";

export default function VerPrecios() {
  return (
    <>
      <Precios />
      <FAQ />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">
          Lo que opinan nuestros usuarios
        </h2>
        <div
          className="senja-embed my-8"
          data-id="4618684c-e52c-4eb6-a906-8e74c0a4bb15"
          data-mode="shadow"
          data-lazyload="false"
          style={{ display: "block" }}
        ></div>
      </div>
    </>
  );
}
